<template>
  <div class="UIChatHeader">
    <div class="UIChat__chat-title">
      <slot />
    </div>
    <UIButtonIcon
      v-if="!hideCloseIcon"
      class="UIChat__chat-close"
      icon="close"
      @click="() => emit('close')"
    />
  </div>
</template>

<script lang="ts" setup>
import UIButtonIcon from '@/views/components/ui/buttons/UIButtonIcon.vue'

const emit = defineEmits(['close'])
defineProps({
  hideCloseIcon: {
    type: Boolean,
    default: false,
  },
})
</script>

<style lang="scss" scoped>
.UIChatHeader {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  background: $newcolor-primary-main;
  color: $color-white;
  --color: #{$color-white};
  .UIChat__chat-title {
    display: flex;
    align-items: flex-start;

    .UIIcon-wrapper {
      margin-right: 0.4rem;
      --size: 1.4rem;
    }
  }
  .UIChat__chat-close {
    --size: 1.25rem;
  }
}
</style>
