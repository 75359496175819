import { FeatureFlag } from '@/apiClient/types/featureFlag'
import { RouteRecordRaw } from 'vue-router'

export const settingsRoutes: RouteRecordRaw[] = [
  {
    path: '/settings',
    meta: {
      breadcrumb: 'settings',
    },
    children: [
      {
        path: '/settings',
        name: 'Settings',
        component: () => import('@/views/pages/co2/Settings/Index.vue'),
      },
      {
        path: '/settings/organisation',
        name: 'SettingsOrganisation',
        meta: {
          breadcrumb: 'organisations',
        },
        component: () =>
          import('@/views/pages/co2/SettingsOrganisation/Index.vue'),
      },
      {
        path: '/settings/profile',
        name: 'SettingsProfile',
        meta: {
          breadcrumb: 'profile',
        },
        component: () => import('@/views/pages/co2/SettingsProfile/Index.vue'),
      },
      {
        path: '/settings/locations',
        name: 'SettingsLocations',
        meta: {
          breadcrumb: 'locations',
        },
        component: () =>
          import('@/views/pages/co2/SettingsLocations/Index.vue'),
      },
      {
        path: '/settings/emission/categories',
        name: 'SettingsEmissionCategories',
        meta: {
          breadcrumb: 'categories',
        },
        component: () =>
          import('@/views/pages/co2/SettingsEmissionCategories/Index.vue'),
      },
      {
        path: '/settings/emission/sources',
        name: 'SettingsEmissionSources',
        meta: {
          breadcrumb: 'emissionSources',
        },
        component: () =>
          import('@/views/pages/co2/SettingsEmissionSources/Index.vue'),
      },
      {
        path: '/settings/emission/templates',
        name: 'SettingsEmissionTrackingTemplates',
        meta: {
          featureFlag: FeatureFlag.trackingTemplate,
          breadcrumb: 'emissionTrackingTemplates',
        },
        component: () =>
          import(
            '@/views/pages/co2/SettingsEmissionTrackingTemplates/Index.vue'
          ),
      },
      {
        path: '/settings/org-structure',
        name: 'SettingsOrgStructure',
        redirect: {
          name: 'SettingsOrgStructureCompanies',
        },
        component: () =>
          import('@/views/pages/co2/SettingsOrgStructure/Index.vue'),
        children: [
          {
            path: '/settings/org-structure/companies',
            name: 'SettingsOrgStructureCompanies',
            meta: {
              breadcrumb: 'companies',
            },
            component: () =>
              import(
                '@/views/pages/co2/SettingsOrgStructureCompanies/Index.vue'
              ),
          },
          {
            path: '/settings/org-structure/facilities',
            name: 'SettingsOrgStructureFacilities',
            meta: {
              breadcrumb: 'facilities',
            },
            component: () =>
              import(
                '@/views/pages/co2/SettingsOrgStructureFacilities/Index.vue'
              ),
          },
          {
            path: '/settings/org-structure/product-lines',
            name: 'SettingsOrgStructureProductLines',
            meta: {
              breadcrumb: 'productLines',
              featureFlag: FeatureFlag.productLines,
            },
            component: () =>
              import(
                '@/views/pages/co2/SettingsOrgStructureProductLines/Index.vue'
              ),
          },
        ],
      },
      {
        path: '/settings/my-team',
        name: 'SettingsMyTeam',
        redirect: {
          name: 'SettingsMyTeamMembers',
        },
        component: () => import('@/views/pages/co2/SettingsMyTeam/Index.vue'),
        children: [
          {
            path: '/settings/my-team/members',
            name: 'SettingsMyTeamMembers',
            meta: {
              breadcrumb: 'teamMembers',
            },
            component: () =>
              import('@/views/pages/co2/SettingsMyTeamMembers/Index.vue'),
          },
          {
            path: '/settings/my-team/invites',
            name: 'SettingsMyTeamInvites',
            meta: {
              breadcrumb: 'userInvites',
            },
            component: () =>
              import(
                '@/views/pages/co2/SettingsMyTeamMembersInvites/Index.vue'
              ),
          },
        ],
      },
      {
        path: '/settings/api-documentation',
        name: 'SettingsApiDocumentation',
        meta: {
          breadcrumb: 'breadcrumbs.apiDocumentation',
          featureFlag: FeatureFlag.apiDocumentation,
        },
        component: () =>
          import('@/views/pages/co2/SettingsApiDocumentation/Index.vue'),
      },
    ],
  },
]
