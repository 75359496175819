<template>
  <div id="LayoutAuth" :class="{ 'LayoutAuth__finalise': isFullPage }">
    <div class="LayoutAuth__image">
      <img
        class="LayoutAuth__image-computer"
        src="/images/login-computer.webp"
        width="750"
      />

      <p class="LayoutAuth__heading">
        {{ t('loginEsgText') }}
      </p>
    </div>

    <div class="LayoutAuth__form">
      <div class="LayoutAuth__form-container">
        <img class="LayoutAuth__logo" src="/logo-no-bg.svg" />

        <RouterView />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useFeatureFlagStore } from '@/store/featureFlag'
import { computed, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

const { t } = useI18n()
const route = useRoute()

const isFullPage = computed(() => route.name === 'RegisterFinalisation')

onMounted(() => {
  useFeatureFlagStore().fetch()
})
</script>

<style scoped lang="scss">
#LayoutAuth {
  min-height: 100vh;
  width: 100vw;
  background-color: $color-white;
  display: grid;
  grid-template-columns: 0 auto;

  @include md {
    grid-template-columns: 1fr 500px;
  }

  &.LayoutAuth__finalise {
    grid-template-columns: 0 auto;

    .LayoutAuth__form {
      margin: 0 auto;
      max-width: unset;
    }
  }

  .LayoutAuth__image {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 0.25rem;
    overflow: hidden;

    background-image: radial-gradient(
      at center left,
      #eee6ff 0%,
      $color-grey-100 70%
    );
  }

  .LayoutAuth__image-computer {
    max-width: 80%;
    margin-right: 15%;

    @include lg {
      margin-right: 155px;
    }
  }

  .LayoutAuth__heading {
    font-size: 1.15rem;
    line-height: 1.25;
    max-width: 26rem;
    margin-top: 1.5rem;
    color: $color-grey-700;
    text-align: center;
    text-transform: capitalize;
    font-family: $font-nunito;
    font-weight: 600;

    @include lg {
      font-size: 1.5rem;
    }
  }

  .LayoutAuth__form {
    display: flex;
    justify-content: center;
    padding: 0 2rem;
    flex-direction: column;
    max-width: 31.25rem;
    margin: 0 auto;
    align-items: center;

    @include md {
      margin: 0;
    }
  }

  .LayoutAuth__form-container {
    width: 100%;
  }

  .LayoutAuth__logo {
    width: 14rem;
    margin-left: 2rem;
  }
}
</style>
