import { EMISSION_TYPES } from '@/apiClient/types/emissions'

export const agricultureEmissionTypes = [
  EMISSION_TYPES.ENTERIC_FERMENTATION,
  EMISSION_TYPES.MANURE_MANAGEMENT_DIRECT,
  EMISSION_TYPES.MANURE_MANAGEMENT_INDIRECT,
  EMISSION_TYPES.URINE_AND_DUNG_DEPOSITED,
  EMISSION_TYPES.ORGANIC_FERTILIZERS_MANURE,
  EMISSION_TYPES.INORGANIC_FERTILIZERS,
  EMISSION_TYPES.CROP_RESIDUES,
  EMISSION_TYPES.CULTIVATING_OF_ORGANIC_SOILS,
]
