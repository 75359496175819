<template>
  <Tippy v-bind="props" v-if="!isHidden">
    <slot>
      <UIIcon class="UITooltip__icon" name="help" />
    </slot>

    <template #content>
      <slot name="content" />
    </template>
  </Tippy>
  <slot v-else />
</template>

<script lang="ts" setup>
import { Tippy } from 'vue-tippy'
import UIIcon from '../icons/UIIcon.vue'
import 'tippy.js/dist/tippy.css'

type ITippyProps = InstanceType<typeof Tippy>['$props']

interface Props extends /* @vue-ignore */ ITippyProps {
  arrow?: boolean
  isHidden?: boolean
  interactive?: boolean
  theme?: string
  appendTo?: Element | 'parent' | ((ref: Element) => Element)
  offset?: [number, number]
}

const props = withDefaults(defineProps<Props>(), {
  appendTo: () => () => document.body,
  arrow: true,
  interactive: true,
  theme: 'tooltip',
  offset: () => [0, 5],
})
</script>

<style scoped lang="scss">
.UITooltip__icon {
  color: $color-grey-500;
  --size: 1rem;
}
</style>

<style lang="scss">
.tippy-box[data-theme~='tooltip'] {
  box-shadow: 0 3px 12px 1px rgba(43, 55, 72, 0.15);
  background: $color-white;
  max-width: 22.5rem;
  font-size: 0.825rem;
  line-height: 1.25rem;
  border-radius: 5px;
  border: 1px solid $color-border;
  color: $newcolor-grey-700;
  width: 100%;

  .tippy-content {
    padding: 0.5rem 0.75rem;
    a {
      color: $newcolor-primary-main;
      text-decoration: none;
    }
  }

  & > .tippy-arrow {
    position: absolute;
    width: 9px;
    height: 9px;
    box-sizing: border-box;
    background: $color-white;
    border: none;

    &:before {
      position: absolute;
      width: 9px;
      height: 9px;
      box-sizing: border-box;
      background: $color-white;
      transform: rotate(45deg);
      border: 0;
    }
  }

  &[data-placement^='bottom'] > .tippy-arrow:before {
    border-left: 1px solid $color-border;
    border-top: 1px solid $color-border;
    left: -3px;
  }

  &[data-placement^='top'] > .tippy-arrow:before {
    border-right: 1px solid $color-border;
    border-bottom: 1px solid $color-border;
    left: 3px;
  }

  &[data-placement^='left'] > .tippy-arrow:before {
    border-top: 1px solid $color-border;
    border-right: 1px solid $color-border;
    top: -3px;
  }

  &[data-placement^='right'] > .tippy-arrow:before {
    border-bottom: 1px solid $color-border;
    border-left: 1px solid $color-border;
    top: 3px;
  }

  .UITooltip__icon {
    color: $color-grey-500;
  }
}
</style>
