<template>
  <UIInputText
    class="UIChatInput"
    name="text"
    :placeholder="placeholder"
    @keyup.enter="emit('sendMessage')"
    :isDisabled="isDisabled"
    autocomplete="off"
  >
    <template #icon-after>
      <UIButtonIcon
        @click="emit('sendMessage')"
        icon="sendVariantOutline"
        :isDisabled="isDisabled"
      />
    </template>
  </UIInputText>
</template>

<script lang="ts" setup>
import UIButtonIcon from '@/views/components/ui/buttons/UIButtonIcon.vue'
import UIInputText from '@/views/components/ui/inputs/UIInputText.vue'

defineProps({
  isDisabled: {
    type: Boolean,
    default: false,
  },
  placeholder: {
    type: String,
    default: 'Ask something...',
  },
})

const emit = defineEmits(['sendMessage'])
</script>

<style lang="scss" scoped>
.UIChatInput {
  :deep(.UIInputText__input) {
    border-radius: 0;
    border: none;
    border-top: 1px solid $newcolor-border;
  }
  :deep(.UIInputText__input-icon--after) {
    padding-right: 0.5rem;
  }
}
</style>
