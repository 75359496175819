import { IRecordData, IRecordDataFacility } from './recordData'

export enum EMISSION_TYPES {
  FUEL = 'FUEL',
  ENERGY = 'ENERGY',
  HEATING = 'HEATING',
  REFRIGERANT = 'REFRIGERANT',
  TRANSPORT = 'TRANSPORT',
  SPEND_BASED = 'SPEND_BASED',
  MATERIAL = 'MATERIAL',
  WATER = 'WATER',
  WASTE = 'WASTE',
  TRANSPORT_UPSTREAM = 'TRANSPORT_UPSTREAM',
  TRANSPORT_DOWNSTREAM = 'TRANSPORT_DOWNSTREAM',
  BUSINESS_TRAVEL = 'BUSINESS_TRAVEL',
  HOTEL_STAYS = 'HOTEL_STAYS',
  EMPLOYEE_COMMUTE = 'EMPLOYEE_COMMUTE',
  HOMEWORKING = 'HOMEWORKING',
  CAPITAL_GOODS = 'CAPITAL_GOODS',
  PURCHASED_SERVICES = 'PURCHASED_SERVICES',
  LEASED_ASSETS_UPSTREAM = 'LEASED_ASSETS_UPSTREAM',
  LEASED_ASSETS_DOWNSTREAM = 'LEASED_ASSETS_DOWNSTREAM',
  PROCESSING_OF_SOLD_PRODUCTS = 'PROCESSING_OF_SOLD_PRODUCTS',
  USE_OF_SOLD_PRODUCTS = 'USE_OF_SOLD_PRODUCTS',
  END_OF_LIFE_TREATMENT_OF_SOLD_PRODUCTS = 'END_OF_LIFE_TREATMENT_OF_SOLD_PRODUCTS',
  FRANCHISES = 'FRANCHISES',
  INVESTMENTS = 'INVESTMENTS',
  ENTERIC_FERMENTATION = 'ENTERIC_FERMENTATION',
  MANURE_MANAGEMENT_DIRECT = 'MANURE_MANAGEMENT_DIRECT',
  MANURE_MANAGEMENT_INDIRECT = 'MANURE_MANAGEMENT_INDIRECT',
  URINE_AND_DUNG_DEPOSITED = 'URINE_AND_DUNG_DEPOSITED',
  INORGANIC_FERTILIZERS = 'INORGANIC_FERTILIZERS',
  ORGANIC_FERTILIZERS_MANURE = 'ORGANIC_FERTILIZERS_MANURE',
  CROP_RESIDUES = 'CROP_RESIDUES',
  CULTIVATING_OF_ORGANIC_SOILS = 'CULTIVATING_OF_ORGANIC_SOILS',
}
export enum EMISSION_ENERGY_TYPES {
  MARKET = 'MARKET',
  LOCATION = 'LOCATION',
  BIOGENIC = 'BIOGENIC',
}
export enum EMISSIONS {
  CO2 = 'CO2',
  CH4 = 'CH4',
  CH4_CO2E = 'CH4_CO2E',
  N2O = 'N2O',
  N2O_CO2E = 'N2O_CO2E',
  CO2_BIOGENIC = 'CO2_BIOGENIC',
}
export enum EMISSIONS_ENERGY {
  MARKET = 'MARKET',
  LOCATION = 'LOCATION',
}

export type IEmissionCategory = {
  id: number
  name: string
  subcategory: string
  scope: number
  scopeSubcategory: string
  emissionType: EMISSION_TYPES
  isSilent: boolean
  // Composed values
  emissionSources?: IEmissionSource[]
}

export interface IEmissionCategoryNote extends IEmissionCategoryNoteData {
  id: number
}

export interface IEmissionCategoryNoteData {
  emissionCategoryId: number
  companyId: number
  orgId: number
  note: string
}

export interface IEmissionSource extends IEmissionSourceData {
  id: number
}
export interface IEmissionSourceData {
  id?: number
  orgId: number
  name: string
  emissionCategoryId: number
  emissionType: EMISSION_TYPES
  unitId: number
  isKyoto: boolean
  isDefault: boolean
  version: string

  // Composed values
  isCopy?: boolean
  transportTypeId?: number
}

export type IEmissionSourceWithFactors = IEmissionSourceData & {
  emissionFactors: (IEmissionFactor | IEmissionFactorData)[]
  energyCoefficients: IEnergyCoefficient[]
}

export type IEmissionSourceWithFactorsData = IEmissionSourceData & {
  emissionFactors: IEmissionFactorData[]
  energyCoefficients: IEnergyCoefficientData[]
}

export type IEmissionSourceWithFactorsCopy = IEmissionSourceData & {
  emissionFactors: IEmissionFactorData[]
  energyCoefficients: IEnergyCoefficientData[]
}

export type IEmissionSourceFormOutput = IEmissionSourceData & {
  emissionFactors: IEmissionFactor[]
  energyCoefficients: IEnergyCoefficient[]
}

export type IEmissionSourceFormValues = IEmissionSourceData & {
  source?: Record<EMISSION_FACTOR_TYPES, Record<string, string | undefined>>
  sourceUrl?: Record<EMISSION_FACTOR_TYPES, Record<string, string | undefined>>
  sourceDescription?: Record<
    EMISSION_FACTOR_TYPES,
    Record<string, string | undefined>
  >
  emissionFactors: IEmissionSourceFactorsTree
  energyCoefficients: Record<
    string,
    IEnergyCoefficient | IEnergyCoefficientData
  >
}

export type IEmissionSourceFactorsTree = Record<
  EMISSION_FACTOR_TYPES,
  Record<string, Record<EMISSIONS | EMISSIONS_ENERGY, IEmissionFactor>>
>

export interface ITrackingTemplateData {
  id?: number
  orgId?: number
  name: string
  description?: string
  isDefault?: boolean
}

export interface ITrackingTemplate extends ITrackingTemplateData {
  id: number
}

export interface IEmissionTrackingTemplateWithItems extends ITrackingTemplate {
  emissionSources: WithId<IEmissionSource>[]
}

export interface IEmissionTrackingTemplateForm extends ITrackingTemplate {
  emissionSources: ITrackingTemplateSourceData[]
}

export interface ITrackingTemplateSourceData {
  trackingTemplateId?: number
  emissionSourceId: number
}

export interface ITrackingTemplateSource extends ITrackingTemplateSourceData {
  id: number
}

/**
 * Emission source with all values (CO2e)
 */

export type IEmissionValue = {
  [key in EMISSIONS]: number
}

/**
 * Emission values with metadata flattened (mainly used for energy emissions)
 */
export type IEmissionValueWithMeta = IEmissionValue & {
  [key in EMISSIONS_ENERGY]: {
    [key: number]: number
  }
}

export type IEmissionSourceFlattened = IEmissionSource & IEmissionValue

export type IEmissionSourceVersionedFlattened = IEmissionSourceWithFactors & {
  id: number
  emissionFactorsFlatByVersion: Record<string, IEmissionValue>
} & IEmissionValueWithMeta

export type IEmissionSourceWithRecordData = IEmissionSource & {
  id: number
  recordData: IRecordDataFacility[]
}

export interface IEmissionFactor extends IEmissionFactorData {
  id: number
}

export type IEmissionFactorData = {
  type: EMISSION_FACTOR_TYPES
  orgId?: number
  version: string
  emissionSourceId: number
  value: number
  valueCo2e: number
  isCarbonEquivalent: boolean
  meta: Record<string, any>
  ar: number
  source?: string
  sourceUrl?: string
  sourceDescription?: string

  // Composed values
  isRemoved?: boolean
  inheritedFrom?: string
} & {
  emissions: EMISSIONS
  meta?: {
    locationId: number
    type?: EMISSIONS_ENERGY
  }
}

export interface IEnergyCoefficientData {
  id?: number
  orgId?: number
  version: string
  emissionSourceId: number
  value: number
  source?: string
  sourceUrl?: string
  sourceDescription?: string

  // computed
  inheritedFrom?: string
}

export interface IEnergyCoefficient extends IEnergyCoefficientData {
  id: number
}

export type ICalculatedEmissionRow = {
  records?: IRecordData[]
  isNew: boolean
  co2e: number
  emissionCategoryId: number
  emissionSourceId: number
}

export enum EMISSION_FACTOR_TYPES {
  EC = 'EC',
  TD = 'TD',
  WTT = 'WTT',
  BASE = 'BASE',
}

export interface IIntensityMetricRecord {
  id: number
  orgId?: number
  intensityMetricId: number
  value: number
  emissionCategoriesIds: number[]
  type: INTENSITY_METRIC_TYPE
  intensity: number
  version: string
}

export interface IIntensityMetricData {
  orgId?: number
  name: string
  unit: string
  emissionCategoriesIds: number[]
  type: INTENSITY_METRIC_TYPE
  records: IIntensityMetricRecord[]
}

export interface IIntensityMetric extends IIntensityMetricData {
  id: number
}

export enum INTENSITY_METRIC_TYPE {
  STANDARD = 'STANDARD',
  SPECIFIC = 'SPECIFIC',
}
