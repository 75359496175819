<template>
  <UIDropdown class="UserDropdown" :items="userOptions">
    <template #menu-before>
      <div class="UserDropdown__user-info">
        <div class="UserDropdown__user-info-initials">
          <span v-if="userInitials">{{ userInitials }}</span>
          <UIIcon v-else name="accountOutline" />
        </div>
        <div>
          <div class="UserDropdown__user-info-title">
            {{ user?.firstName }} {{ user?.lastName }}
          </div>
          <div class="UserDropdown__user-info-subtitle">
            {{ user?.email }}
          </div>
        </div>
      </div>
    </template>
    <template #option="{ option }">
      <div>
        <UIButton
          theme="option"
          :to="option.to ? { name: option.to } : undefined"
          :href="option.href"
        >
          <template #icon-before>
            <UIIcon :name="option.icon" />
          </template>
          {{ option.label }}
        </UIButton>
      </div>
    </template>
    <template #menu-after>
      <ul class="UserDropdown__menu-after">
        <li>
          <UIButton theme="option" :to="{ name: 'Logout' }">
            <template #icon-before><UIIcon name="logout" /></template>
            {{ t('signOut') }}
          </UIButton>
        </li>
      </ul>
    </template>
    <UIButtonIcon class="UserDropdown__icon" icon="accountOutline" />
  </UIDropdown>
</template>

<script lang="ts" setup>
import UIDropdown from '@/views/components/ui/dropdowns/UIDropdown.vue'
import UIButtonIcon from '../../../buttons/UIButtonIcon.vue'
import { useUserStore } from '@/store/user'
import { computed } from 'vue'
import UIIcon from '../../../icons/UIIcon.vue'
import UIButton from '../../../buttons/UIButton.vue'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import { useRoute } from 'vue-router'

const { t } = useI18n()

const userStore = useUserStore()

const { user, isSuperadmin, accessToken } = storeToRefs(userStore)

const userInitials = computed(
  () => `${user.value?.firstName?.[0]}${user.value?.lastName?.[0]}`,
)
const route = useRoute()

const dashboardUrl = computed(() =>
  (
    import.meta.env.VITE_SUSTAINAI_DASHBOARD_URL ||
    'https://dashboard.sustainalyse.com'
  ).replace(/\/$/, ''),
)
const userOptions = computed(() => [
  {
    label: t('profileSettings'),
    value: 'profileSettings',
    icon: 'accountOutline',
    to: 'SettingsProfile',
  },
  ...(isSuperadmin.value && dashboardUrl.value
    ? [
        {
          label: t('sustainAIDashboard'),
          value: 'sustainAIDashboard',
          icon: 'laptop',
          href: `${dashboardUrl.value}/api/auth/login?accessToken=${
            accessToken.value
          }&origin=${window.location.origin + route.fullPath}`,
        },
      ]
    : []),
])
</script>

<style scoped lang="scss">
.UserDropdown {
  width: 2rem;
  height: 2rem;
  display: flex;
  .UserDropdown__icon {
    --size: 1em;
    --icon-color: #{$color-white};
    border-radius: 50%;
    overflow: hidden;
    background-color: $newcolor-primary-light;
    padding: 0.75rem;
  }
}

.UserDropdown__user-info {
  padding: 1.25rem 1.75rem;
  display: flex;
  align-items: center;
  background: $newcolor-secondary-lighter;
  border-bottom: 1px solid $newcolor-grey-200;

  .UserDropdown__user-info-initials {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.875rem;
    color: $color-white;
    background-color: $newcolor-primary-light;
    border-radius: 50%;
    width: 2.5rem;
    height: 2.5rem;
    margin-right: 0.5rem;
    font-weight: 500;
    letter-spacing: 0.06rem;
  }

  .UserDropdown__user-info-title {
    font-size: 0.925rem;
    font-weight: 500;
    color: $newcolor-secondary-main;
  }

  .UserDropdown__user-info-subtitle {
    font-size: 0.75rem;
    color: $newcolor-grey-500;
  }

  :deep(.UIIcon) {
    --color: #{$color-white};
    --size: 1.25rem;
  }
}

:deep(.UIButton.UIButton--theme-option) {
  --font-size: 0.8125rem;
  --color: #526484;

  display: flex;

  &:hover {
    background: $color-white;
  }
  .UIButton__content {
    font-weight: 700;
  }
}

.UserDropdown__menu-after {
  padding: 0.75rem 0;
  border-top: 1px solid $newcolor-grey-200;

  li {
    list-style: none;
  }
}
</style>
