import { EMISSION_TYPES } from '@/apiClient/types/emissions'
import { FeatureFlag } from '@/apiClient/types/featureFlag'
import { RouteRecordRaw } from 'vue-router'

export const emissionRoutes: RouteRecordRaw[] = [
  {
    path: '/emissions',
    meta: {
      breadcrumb: 'trackingEmissions',
    },
    children: [
      {
        path: '/emissions',
        name: 'Emissions',
        component: () => import('@/views/pages/co2/Emissions/Index.vue'),
      },
      {
        path: '/emissions/buildings',
        name: 'EmissionsBuildings',
        meta: {
          breadcrumb: 'buildings',
          featureFlag: FeatureFlag.scopeOneAndTwo,
        },
        component: () =>
          import('@/views/pages/co2/EmissionsBuildings/Index.vue'),
      },
      {
        path: '/emissions/transport',
        name: 'EmissionsTransport',
        meta: {
          breadcrumb: 'vehicles',
          featureFlag: FeatureFlag.scopeOneAndTwo,
        },
        component: () =>
          import('@/views/pages/co2/EmissionsTransport/Index.vue'),
      },
      {
        path: '/emissions/refrigerants',
        name: 'EmissionsRefrigerants',
        meta: {
          breadcrumb: 'refrigerants',
          featureFlag: FeatureFlag.scopeOneAndTwo,
        },
        component: () =>
          import('@/views/pages/co2/EmissionsRefrigerants/Index.vue'),
      },
      {
        path: '/emissions/spend-based',
        name: 'EmissionsSpendBased',
        meta: {
          breadcrumb: 'spendBased',
          featureFlag: FeatureFlag.scopeThree,
        },
        component: () =>
          import('@/views/pages/co2/EmissionsSpendBased/Index.vue'),
      },
      {
        path: '/emissions/capital-goods',
        name: 'EmissionsCapitalGoods',
        meta: {
          breadcrumb: 'capitalGoods',
          featureFlag: FeatureFlag.scopeThree,
        },
        component: () =>
          import('@/views/pages/co2/EmissionsCapitalGoods/Index.vue'),
      },
      {
        path: '/emissions/materials',
        name: 'EmissionsMaterials',
        meta: {
          breadcrumb: 'materials',
          featureFlag: FeatureFlag.scopeThree,
        },
        component: () =>
          import('@/views/pages/co2/EmissionsMaterials/Index.vue'),
      },
      {
        path: '/emissions/water',
        name: 'EmissionsWater',
        meta: {
          breadcrumb: 'water',
          featureFlag: FeatureFlag.scopeThree,
        },
        component: () => import('@/views/pages/co2/EmissionsWater/Index.vue'),
      },
      {
        path: '/emissions/waste',
        name: 'EmissionsWaste',
        meta: {
          breadcrumb: 'waste',
          featureFlag: FeatureFlag.scopeThree,
        },
        component: () => import('@/views/pages/co2/EmissionsWaste/Index.vue'),
      },
      {
        path: '/emissions/transportation-downstream',
        name: 'EmissionsTransportDownstream',
        meta: {
          breadcrumb: 'downstreamTransportation',
          featureFlag: FeatureFlag.scopeThree,
        },
        component: () =>
          import('@/views/pages/co2/EmissionsTransportDownstream/Index.vue'),
      },
      {
        path: '/emissions/transportation-upstream',
        name: 'EmissionsTransportUpstream',
        meta: {
          breadcrumb: 'upstreamTransportation',
          featureFlag: FeatureFlag.scopeThree,
        },
        component: () =>
          import('@/views/pages/co2/EmissionsTransportUpstream/Index.vue'),
      },
      {
        path: '/emissions/employee-commute',
        name: 'EmissionsEmployeeCommute',
        meta: {
          breadcrumb: 'employeeCommute',
          featureFlag: FeatureFlag.scopeThree,
        },
        component: () =>
          import('@/views/pages/co2/EmissionsEmployeeCommute/Index.vue'),
      },
      {
        path: '/emissions/homeworking',
        name: 'EmissionsHomeworking',
        meta: {
          breadcrumb: 'homeworking',
          featureFlag: FeatureFlag.scopeThree,
        },
        component: () =>
          import('@/views/pages/co2/EmissionsHomeworking/Index.vue'),
      },
      {
        path: '/emissions/business-travel',
        name: 'EmissionsBusinessTravel',
        meta: {
          breadcrumb: 'businessTravel',
          featureFlag: FeatureFlag.scopeThree,
        },
        component: () =>
          import('@/views/pages/co2/EmissionsBusinessTravel/Index.vue'),
      },
      {
        path: '/emissions/hotel-stays',
        name: 'EmissionsHotelStays',
        meta: {
          breadcrumb: 'hotelStays',
          featureFlag: FeatureFlag.scopeThree,
        },
        component: () =>
          import('@/views/pages/co2/EmissionsHotelStays/Index.vue'),
      },
      {
        path: '/emissions/purchased-services',
        name: 'EmissionsPurchasedServices',
        meta: {
          breadcrumb: 'emissions.purchasedServices',
          featureFlag: FeatureFlag.scopeThree,
        },
        component: () =>
          import('@/views/pages/co2/EmissionsPurchasedServices/Index.vue'),
      },
      {
        path: '/emissions/leased-assets-upstream',
        name: 'EmissionsLeasedAssetsUpstream',
        meta: {
          breadcrumb: 'emissions.leasedAssetsUpstream',
          featureFlag: FeatureFlag.scopeThree,
        },
        component: () =>
          import('@/views/pages/co2/EmissionsLeasedAssetsUpstream/Index.vue'),
      },
      {
        path: '/emissions/leased-assets-downstream',
        name: 'EmissionsLeasedAssetsDownstream',
        meta: {
          breadcrumb: 'emissions.leasedAssetsDownstream',
          featureFlag: FeatureFlag.scopeThree,
        },
        component: () =>
          import('@/views/pages/co2/EmissionsLeasedAssetsDownstream/Index.vue'),
      },
      {
        path: '/emissions/processing-of-sold-products',
        name: 'EmissionsProcessingOfSoldProducts',
        meta: {
          breadcrumb: 'emissions.processingOfSoldProducts',
          featureFlag: FeatureFlag.scopeThree,
        },
        component: () =>
          import(
            '@/views/pages/co2/EmissionsProcessingOfSoldProducts/Index.vue'
          ),
      },
      {
        path: '/emissions/use-of-sold-products',
        name: 'EmissionsUseOfSoldProducts',
        meta: {
          breadcrumb: 'emissions.useOfSoldProducts',
          featureFlag: FeatureFlag.scopeThree,
        },
        component: () =>
          import('@/views/pages/co2/EmissionsUseOfSoldProducts/Index.vue'),
      },
      {
        path: '/emissions/end-of-life-treatment-of-sold-products',
        name: 'EmissionsEndOfLifeTreatmentOfSoldProducts',
        meta: {
          breadcrumb: 'emissions.endOfLifeTreatmentOfSoldProducts',
          featureFlag: FeatureFlag.scopeThree,
        },
        component: () =>
          import(
            '@/views/pages/co2/EmissionsEndOfLifeTreatmentOfSoldProducts/Index.vue'
          ),
      },
      {
        path: '/emissions/franchises',
        name: 'EmissionsFranchises',
        meta: {
          breadcrumb: 'emissions.franchises',
          featureFlag: FeatureFlag.scopeThree,
        },
        component: () =>
          import('@/views/pages/co2/EmissionsFranchises/Index.vue'),
      },
      {
        path: '/emissions/investments',
        name: 'EmissionsInvestments',
        meta: {
          breadcrumb: 'emissions.investments',
          featureFlag: FeatureFlag.scopeThree,
        },
        component: () =>
          import('@/views/pages/co2/EmissionsInvestments/Index.vue'),
      },
      {
        path: '/emissions/intensity-metrics',
        name: 'IntensityMetrics',
        meta: {
          breadcrumb: 'page.intensityMetrics',
        },
        component: () => import('@/views/pages/co2/IntensityMetrics/Index.vue'),
      },
      {
        path: '/emissions/enteric-fermentation',
        name: 'EntericFermentation',
        meta: {
          breadcrumb: 'emissions.entericFermentation',
        },
        component: () =>
          import('@/views/pages/co2/EmissionsFacilityUniversal/Index.vue'),
        props: {
          emissionTypes: [EMISSION_TYPES.ENTERIC_FERMENTATION],
          description: 'emissions.entericFermentation.inputDescription',
          title: 'emissions.entericFermentation',
        },
      },

      {
        path: '/emissions/manure-management',
        name: 'ManureManagement',
        meta: {
          breadcrumb: 'emissions.manureManagement',
        },
        component: () =>
          import('@/views/pages/co2/EmissionsFacilityUniversal/Index.vue'),
        props: {
          emissionTypes: [
            EMISSION_TYPES.MANURE_MANAGEMENT_DIRECT,
            EMISSION_TYPES.MANURE_MANAGEMENT_INDIRECT,
            EMISSION_TYPES.URINE_AND_DUNG_DEPOSITED,
          ],
          description: 'emissions.manureManagement.inputDescription',
          title: 'emissions.manureManagement',
        },
      },
      {
        path: '/emissions/use-of-organic-and-inorganic-fertilizers',
        name: 'UseOfOrganicAndInorganicFertilizers',
        meta: {
          breadcrumb: 'emissions.useOfOrganicAndInorganicFertilizers',
        },
        component: () =>
          import('@/views/pages/co2/EmissionsFacilityUniversal/Index.vue'),
        props: {
          emissionTypes: [
            EMISSION_TYPES.ORGANIC_FERTILIZERS_MANURE,
            EMISSION_TYPES.INORGANIC_FERTILIZERS,
          ],
          description:
            'emissions.useOfOrganicAndInorganicFertilizers.inputDescription',
          title: 'emissions.useOfOrganicAndInorganicFertilizers',
        },
      },
      {
        path: '/emissions/crop-residues',
        name: 'CropResidues',
        meta: {
          breadcrumb: 'emissions.cropResidues',
        },
        component: () =>
          import('@/views/pages/co2/EmissionsFacilityUniversal/Index.vue'),
        props: {
          emissionTypes: [EMISSION_TYPES.CROP_RESIDUES],
          description: 'emissions.cropResidues.inputDescription',
          title: 'emissions.cropResidues',
        },
      },
      {
        path: '/emissions/cultivating-of-organic-soils',
        name: 'CultivatingOfOrganicSoils',
        meta: {
          breadcrumb: 'emissions.cultivatingOfOrganicSoils',
        },
        component: () =>
          import('@/views/pages/co2/EmissionsFacilityUniversal/Index.vue'),
        props: {
          emissionTypes: [EMISSION_TYPES.CULTIVATING_OF_ORGANIC_SOILS],
          description: 'emissions.cultivatingOfOrganicSoils.inputDescription',
          title: 'emissions.cultivatingOfOrganicSoils',
        },
      },
    ],
  },
  {
    path: '/calculated-emissions',
    name: 'CalculatedEmissions',
    meta: {
      breadcrumb: 'calculatedEmissions',
      featureFlag: FeatureFlag.scopeThree,
    },
    component: () => import('@/views/pages/co2/CalculatedEmissions/Index.vue'),
  },
]
