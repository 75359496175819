import { UserRoleGroupsBEMap } from './user'

export interface IFeatureFlag extends IFeatureFlagData {
  id: number
}

export interface IFeatureFlagData {
  name: string
  key: string
  role?: keyof typeof UserRoleGroupsBEMap
  description: string
  isEnabled: boolean
  orgIds: number[]
}

// enum of all feature keys
export enum FeatureFlag {
  trackingTemplate = 'trackingTemplate',
  defaultEmissionSourcesActions = 'defaultEmissionSourcesActions',
  scopeOneAndTwo = 'scopeOneAndTwo',
  scopeOneAgriculture = 'scopeOneAgriculture',
  scopeThree = 'scopeThree',
  consolidation = 'consolidation',
  esgPlatform = 'esgPlatform',
  spendBasedCategory = 'spendBasedCategory',
  ghgPlatform = 'ghgPlatform',
  aiAssistant = 'aiAssistant',
  validation = 'validation',
  marketInsights = 'marketInsights',
  productLines = 'productLines',
  euTaxonomy = 'euTaxonomy',
  climateRisksAssessment = 'climateRisksAssessment',
  doubleMateriality = 'doubleMateriality',
  apiDocumentation = 'apiDocumentation',
  ghgDataImport = 'ghgDataImport',
  esgAnnualReportExport = 'esgAnnualReportExport',
  comingSoonLabels = 'comingSoonLabels',
}
