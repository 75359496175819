<template>
  <li
    :class="[
      'TheSidebarMenuItem',
      {
        'TheSidebarMenuItem--is-child': isChild,
      },
    ]"
  >
    <slot>
      <Component
        :is="item.to ? 'RouterLink' : 'a'"
        v-bind="routeProps"
        class="TheSidebarMenuItem__link"
        activeClass="TheSidebarMenuItem__link--is-active"
        @click="$emit('close')"
      >
        <div class="TheSidebarMenuItem__icon-container">
          <UIIcon
            v-if="item.icon"
            :name="item.icon"
            class="TheSidebarMenuItem__icon"
          />
        </div>
        {{ item.label }}

        <div
          v-if="item.isComingSoon && showComingSoonLabels"
          class="TheSidebarMenuItem__coming-soon"
        >
          Coming soon
        </div>

        <div
          v-else-if="$slots['toggleIcon']"
          class="TheSidebarMenuItem__toggle-icon"
        >
          <slot name="toggleIcon" />
        </div>
      </Component>
    </slot>
  </li>
</template>

<script lang="ts" setup>
import { PropType, computed } from 'vue'
import UIIcon from '@/views/components/ui/icons/UIIcon.vue'
import { useFeatureFlagStore } from '@/store/featureFlag'
import { ITheSidebarMenuItem } from '../types'
import { FeatureFlag } from '@/apiClient/types/featureFlag'

defineEmits(['close'])
const props = defineProps({
  item: { type: Object as PropType<ITheSidebarMenuItem>, required: true },
  isChild: { type: Boolean, default: false },
})
const { hasFeature } = useFeatureFlagStore()
const showComingSoonLabels = computed(() =>
  hasFeature(FeatureFlag.comingSoonLabels),
)

const routeProps = computed(() => {
  if (props.item.to) {
    return { to: props.item.to }
  } else {
    return { href: '#' }
  }
})
</script>

<style scoped lang="scss">
.TheSidebarMenuItem {
  padding: 0.5rem 0;
  list-style: none;
  font-size: 0.875rem;
  font-weight: 700;

  &--is-child {
    font-size: 0.8125rem;
    font-weight: 400;
  }

  .TheSidebarMenuItem__icon-container {
    margin-right: 0.625rem;
    width: 1.125rem;
    display: flex;
    align-items: center;
  }

  .TheSidebarMenuItem__coming-soon {
    font-size: 0.5rem;
    line-height: 1;
    font-weight: 700;
    margin-left: auto;
    color: $newcolor-primary-main;
    background-color: $newcolor-primary-pale;
    padding: 3px;
    border-radius: 3px;
    white-space: nowrap;
  }

  .TheSidebarMenuItem__icon {
    @include trans;
    --size: 1.125rem;
  }

  .TheSidebarMenuItem__link {
    @include trans;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: $newcolor-secondary-main;
    line-height: 1.25rem;

    &:hover,
    &--is-active {
      color: $newcolor-primary-light;

      .TheSidebarMenuItem__icon {
        --color: #{$newcolor-primary-light};
      }
    }
  }

  .TheSidebarMenuItem__toggle-icon {
    margin-left: auto;
    display: flex;
  }
}
</style>
