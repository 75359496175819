import { IActionLog } from './types/actionLog'
import {
  IRecordDataCompany,
  IRecordDataFacility,
  IRecordDataProduction,
  IRecordDataRow,
} from './types/recordData'
import { IFeatureFlag } from './types/featureFlag'
import { getService } from '.'
import { ICompany } from './types/company'
import { IOrganisation } from './types/organisation'
import { IUser, IUserSetting } from './types/user'
import { IFacility } from './types/facility'
import { IProductLine } from './types/productLine'
import {
  IEmissionCategory,
  IEmissionSource,
  IEmissionFactor,
  IEnergyCoefficient,
  ITrackingTemplateSource,
  IEmissionTrackingTemplateForm,
  IIntensityMetric,
  IIntensityMetricRecord,
  IEmissionCategoryNote,
} from './types/emissions'
import { IFile, IFileDownload, IRecordDataRowFile } from './types/file'
import { ILocation } from './types/location'
import { IRegion } from './types/region'
import { IUnit, IUnitMultiplier } from './types/unit'
import { IUserInvite } from './types/userInvite'
import {
  IEmissionsIntensity,
  IEnergyConsumption,
  IEnergyIntensity,
  IOverview,
  IReportingSummary,
} from './types/overview'
import { ISheetExport, ISheetExportResponse } from './types/sheetExport'
import { Params } from '@feathersjs/feathers'
import { ITransportType, ITransportTypeEmissionSource } from './types/transport'
import {
  IEsgFrameworkStandard,
  IEsgProject,
  IEsgProjectAnswer,
  IEsgProjectRequirementGroup,
  IEsgReport,
  IEsgRequirement,
  IEsgRequirementGroup,
  IEsgTag,
  IEsgParagraph,
  IEsgAnnualReport,
} from './types/esg'
import { useUserStore } from '@/store/user'
import { ITask, ITaskParticipant } from '@/apiClient/types/task'
import { IAssistantChatItem } from './types/assistantChat'
import {
  IValidation,
  IValidationConfig,
  IValidationResultItem,
} from './types/validation'

export const userService = getService<IUser>('user')
// TODO: implement global data sync with sockets
userService.on('patched', (result) => {
  const userStore = useUserStore()
  if (userStore.user?.id === result.id) {
    useUserStore().setUser(result)
  }
})

export const userSettingService = getService<IUserSetting>('userSetting')
export const userInviteService = getService<IUserInvite>('userInvite')
export const authmanagementService = getService('authmanagement')
export const locationService = getService<ILocation>('location')
export const regionService = getService<IRegion>('region')
export const countryService = getService<ILocation>('country')
export const orgService = getService<IOrganisation>('organisation')
export const actionLogService = getService<IActionLog>('actionLog')
export const companyService = getService<ICompany>('company')
export const facilityService = getService<IFacility>('facility')
export const productLineService = getService<IProductLine>('productLine')
export const sheetExportService = {
  create: (data: ISheetExport, params?: Params) =>
    getService('sheetExport').create(
      data,
      params,
    ) as Promise<ISheetExportResponse>,
}
export const organisationImportExportService = {
  get: (id: number, params?: Params) =>
    getService('organisationImportExport').get(
      id,
      params,
    ) as Promise<ISheetExportResponse>,
  create: (data: FormData, params?: Params) =>
    getService('organisationImportExport').create(data, {
      ...params,
      headers: {
        'content-type': 'multipart/form-data',
      },
    }),
}

// OVERVIEWS
export const overviewService = getService<IOverview>('overview')
export const energyIntensityService =
  getService<IEnergyIntensity>('energyIntensity')
export const emissionsIntensityService =
  getService<IEmissionsIntensity>('emissionsIntensity')
export const energyConsumptionService =
  getService<IEnergyConsumption>('energyConsumption')
export const reportingSummaryService =
  getService<IReportingSummary>('reportingSummary')

export const fileDownloadService = getService<IFileDownload>('fileDownload')
export const uploadService = {
  create: (data: FormData, params?: Params) =>
    getService('upload').create(data, {
      ...params,
      headers: {
        'content-type': 'multipart/form-data',
      },
    }),
}
export const fileService = getService<IFile>('file')
export const recordDataRowFileService =
  getService<IRecordDataRowFile>('recordDataRowFile')

// UNITS
export const unitService = getService<IUnit>('unit')
export const unitMultiplierService =
  getService<IUnitMultiplier>('unitMultiplier')

// EMISSIONS
export const emissionCategoryService =
  getService<IEmissionCategory>('emissionCategory')

export const emissionSourceService =
  getService<IEmissionSource>('emissionSource')

export const emissionFactorService =
  getService<IEmissionFactor>('emissionFactor')

export const energyCoefficientService =
  getService<IEnergyCoefficient>('energyCoefficient')

export const intensityMetricService =
  getService<IIntensityMetric>('intensityMetric')

export const intensityMetricRecordService = getService<IIntensityMetricRecord>(
  'intensityMetricRecord',
)

// EMISSION RECORD DATA
export const recordDataFacilityService =
  getService<IRecordDataFacility>('recordDataFacility')

export const recordDataCompanyService =
  getService<IRecordDataCompany>('recordDataCompany')

export const recordDataProductionService = getService<IRecordDataProduction>(
  'recordDataProduction',
)
export const recordDataRowService = getService<IRecordDataRow>('recordDataRow')
export const recordDataRowDuplicationService = getService(
  'recordDataRowDuplication',
)

export const emissionTrackingTemplateService =
  getService<IEmissionTrackingTemplateForm>('trackingTemplate')

export const emissionTrackingTemplateSourceService =
  getService<ITrackingTemplateSource>('trackingTemplateSource')

// FEATURE FLAG
export const featureFlagService = getService<IFeatureFlag>('featureFlag')

// TRANSPORT
export const transportTypeService = getService<ITransportType>('transportType')
export const transportTypeEmissionSourceService =
  getService<ITransportTypeEmissionSource>('transportTypeEmissionSource')

// ESG
export const esgFrameworkStandardService = getService<IEsgFrameworkStandard>(
  'esgFrameworkStandard',
)
export const esgRequirementGroupService = getService<IEsgRequirementGroup>(
  'esgRequirementGroup',
)
export const esgRequirementService =
  getService<IEsgRequirement>('esgRequirement')
export const esgTagService = getService<IEsgTag>('esgTag')
export const esgProjectService = getService<IEsgProject>('esgProject')
export const esgProjectRequirementGroupService =
  getService<IEsgProjectRequirementGroup>('esgProjectRequirementGroup')
export const esgProjectAnswerService =
  getService<IEsgProjectAnswer>('esgProjectAnswer')
export const esgReportService = getService<IEsgReport>('esgReport')
export const esgParagraphService = getService<IEsgParagraph>('esgParagraph')

export const assistantService = getService<IAssistantChatItem>('assistant')
export const assistantLlamaService = getService<IAssistantChatItem>(
  'assistanLlamaService',
)
export const emissionCategoryNoteService = getService<IEmissionCategoryNote>(
  'emissionCategoryNote',
)

export const validationService = getService<IValidation>('validation')
export const validationResultItemService = getService<IValidationResultItem>(
  'validationResultItem',
)
export const validationConfigService =
  getService<IValidationConfig>('validationConfig')
// Tasks

export const taskService = getService<ITask>('task')

export const taskParticipantService =
  getService<ITaskParticipant>('taskParticipant')

export const esgPrefillWithEsgService = getService<any>('esgPrefillWithGhg')

export const sustainAiService = getService<any>('sustainAi')

export const esgAnnualReportService =
  getService<IEsgAnnualReport>('esgAnnualReport')
